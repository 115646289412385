/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Article.module.scss
 *
 * @author: Panasonic, developer
 *
 */

.wrapper {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.title {
  margin-bottom: 10px;
}
