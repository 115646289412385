/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: _colors.scss
 *
 * @author: Panasonic, developer
 *
 */

$primary: #0041c0;
$primary-hover: #063693;

$white: #fff;
$grey: #eee;
$light-black: #484848;
$black: #333333;
$dark-black: #111111;

$light-blue: #92b2f0;
$success-green: #1fd487;
$error-red: #fe5630;
$caption-grey: #999;
$main-bgc: #fafbfe;
