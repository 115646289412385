/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: _variables.scss
 *
 * @author: Panasonic, developer
 *
 */

$header-height: 70px;
$footer-height: 70px;

$font-small: 12px;
$font-regular: 14px;
$font-large: 16px;
$font-heading-1: 30px;
$font-heading-2: 18px;
