/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Main.module.scss
 *
 * @author: Panasonic, developer
 *
 */
/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: common.scss
 *
 * @author: Panasonic, developer
 *
 */
/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: _colors.scss
 *
 * @author: Panasonic, developer
 *
 */
/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: _variables.scss
 *
 * @author: Panasonic, developer
 *
 */
.wrapper {
  flex: 1;
  background-color: #fafbfe;
  padding-bottom: 20px; }
