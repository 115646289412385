/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Link.module.scss
 *
 * @author: Panasonic, developer
 *
 */

@import '../../styles/common.scss';

.wrapper {
  color: $primary;
  font-size: $font-regular;
  text-decoration: none;
  display: inline-block;
}

.linkLight {
  color: $light-blue;
  font-size: $font-small;

  &:hover {
    color: $white;
  }
}
