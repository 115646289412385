/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Text.module.scss
 *
 * @author: Panasonic, developer
 *
 */

@import '../../styles/common.scss';

@mixin heading {
  font-weight: bold;
  margin: 0;
  color: $dark-black;
}

.body1 {
  font-size: $font-small;
}

.body2 {
  font-size: $font-regular;
}

.body3 {
  font-size: $font-large;
}

.paragraph {
  font-size: $font-regular;
  color: $light-black;
  margin: 20px 0;
  line-height: 20px;
}

.heading1 {
  @include heading;
  font-size: $font-heading-1;
}

.heading2 {
  @include heading;
  line-height: 20px;
  font-size: $font-heading-2;
}

.heading3 {
  @include heading;
  font-size: $font-large;
}

.light {
  color: $light-blue;
}
