/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: HarleyDavidson.module.scss
 *
 * @author: Panasonic, developer
 *
 */

.wrapper {
  padding: 40px 0;
}

.content {
  composes: article-container from global;
}

.pageTitle {
  margin-bottom: 40px;
}

.link {
  margin: 20px 0;
}

.contactParagraph {
  margin-bottom: 10px;
}
