/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: global.scss
 *
 * @author: Panasonic, developer
 *
 */

.route-container {
  width: 95%;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    max-width: 1140px;
    margin: 0 auto;
  }
}

.article-container {
  width: 95%;
  margin: 0 auto;

  @media screen and (min-width: 1200px) {
    max-width: 750px;
    margin: 0 auto;
  }
}
