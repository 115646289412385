/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Footer.module.scss
 *
 * @author: Panasonic, developer
 *
 */
/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: common.scss
 *
 * @author: Panasonic, developer
 *
 */
/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: _colors.scss
 *
 * @author: Panasonic, developer
 *
 */
/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: _variables.scss
 *
 * @author: Panasonic, developer
 *
 */
.wrapper {
  height: 70px;
  background-color: #0041c0; }

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  composes: route-container from global; }

.copyright {
  display: flex;
  flex-direction: column; }
