/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Footer.module.scss
 *
 * @author: Panasonic, developer
 *
 */

@import '../../styles/common.scss';

.wrapper {
  height: $footer-height;
  background-color: $primary;
}

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  composes: route-container from global;
}

.copyright {
  display: flex;
  flex-direction: column;
}
