/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: Text.module.scss
 *
 * @author: Panasonic, developer
 *
 */
/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: common.scss
 *
 * @author: Panasonic, developer
 *
 */
/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: _colors.scss
 *
 * @author: Panasonic, developer
 *
 */
/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: _variables.scss
 *
 * @author: Panasonic, developer
 *
 */
.body1 {
  font-size: 12px; }

.body2 {
  font-size: 14px; }

.body3 {
  font-size: 16px; }

.paragraph {
  font-size: 14px;
  color: #484848;
  margin: 20px 0;
  line-height: 20px; }

.heading1 {
  font-weight: bold;
  margin: 0;
  color: #111111;
  font-size: 30px; }

.heading2 {
  font-weight: bold;
  margin: 0;
  color: #111111;
  line-height: 20px;
  font-size: 18px; }

.heading3 {
  font-weight: bold;
  margin: 0;
  color: #111111;
  font-size: 16px; }

.light {
  color: #92b2f0; }
