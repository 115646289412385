/*!
 * PASA Confidentiality Notice:
 * This source code and information contained herewith may be legally privileged and confidential
 * Any dissemination, distribution or copy of this source code is strictly prohibited.
 *
 * Copyright (C) 2019, Panasonic Automotive Systems Company of America
 * All Rights Reserved
 *
 *
 * @file: PageTitle.module.scss
 *
 * @author: Panasonic, developer
 *
 */

@import '../../styles/common.scss';

.wrapper {
  position: relative;
  font-size: $font-large;
  color: $white;
  padding-left: 20px;
  margin-left: 10px;
  margin-top: 4px;

  &::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 25px;
    width: 1px;
    background-color: $white;
    opacity: 0.2;
  }
}
